import { ItemFinderInputV1, ItemFinderOutputV1, sqDatasourcesApi, sqItemsApi } from '@/sdk';
import { errorToast } from '@/utilities/toast.utilities';
import { updateSearch } from '@/itemFinder/itemFinder.actions';
import * as models from 'sdk/model/models';
import { PropertySearchV1 } from '@/sdk/model/PropertySearchV1';
import { OperatorEnum, PropertyFilterInputV1 } from '@/sdk/model/PropertyFilterInputV1';
import { PropertyInputV1 } from '@/sdk/model/PropertyInputV1';
import { FixedListSearchV1 } from '@/sdk/model/FixedListSearchV1';
import { AIPayload, Search, SearchType, SearchTypeEnum } from '@/itemFinder/itemFinder.constants';
import { doTrack } from '@/track/track.service';
import { TrackInformation } from '@/track/track.types';
import { SelectOptionIF } from '@/usage/Usage.page';
import { Datasource } from '@/search/search.types';
import { sqItemFinderStore } from '@/core/core.stores';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { base64guid } from '@/utilities/utilities';
import { DatasourceLabel } from '@/itemFinder/itemFinder.store';

export const createUpdateItemFinder = async (
  itemFinder: ItemFinderInputV1,
  itemFinderId?: string,
  showInVantage?: boolean,
): Promise<ItemFinderOutputV1> => {
  const { data: itemFinderOutput } = await (itemFinderId
    ? sqItemsApi.updateItemFinder(itemFinder, { id: itemFinderId })
    : sqItemsApi.createItemFinder(itemFinder));

  if (!showInVantage) {
    const trackInformation: TrackInformation = {
      scopedTo: itemFinder.scopedTo,
      searchType: itemFinder.propertySearches ? 'Property' : 'Manual',
      propertySearches: JSON.stringify(itemFinder.propertySearches),
      fixedListSearches: JSON.stringify(itemFinder.fixedListSearches),
    };
    doTrack('Scaling Table Configuration', 'Create Item Finder', trackInformation);
  }

  if (!showInVantage && itemFinder.cronSchedule) {
    doTrack('Scaling Table Configuration', 'Set Schedule', {
      numberOfSchedules: itemFinder?.cronSchedule.length,
      schedules: itemFinder?.cronSchedule,
    });
  }

  return itemFinderOutput;
};

export const removeDatasourceProperties = (searchId: string) => {
  const search = sqItemFinderStore.searches.find((search) => search.searchId === searchId);

  if (search) {
    const datasourceIdProperty = search.predicates?.find(
      (predicate) => predicate.propertyName === SeeqNames.Properties.DatasourceId,
    );
    const datasourceClassProperty = search.predicates?.find(
      (predicate) => predicate.propertyName === SeeqNames.Properties.DatasourceClass,
    );

    if (datasourceClassProperty) {
      updateSearch({
        searchId,
        action: 'UPDATE_PROPERTY',
        propertyId: datasourceClassProperty.propertyId,
        propertyData: {
          datasources: [],
        },
      });
    }

    if (datasourceIdProperty) {
      updateSearch({
        searchId,
        propertyId: datasourceIdProperty.propertyId,
        action: 'REMOVE_PROPERTY',
      });
    }
  }
};

export const addDatasourcePropertyAndValue = (
  searchId: string,
  propertyId: string,
  valueId: string,
  selectedDatasources: SelectOptionIF<Datasource>[],
) => {
  const datasourceLabel: DatasourceLabel[] = [];
  const selectedDatasourceIds = selectedDatasources.map((datasource) => datasource.value.datasourceId as string);
  const datasourceClassValues: { value: string; propertyId: string; searchId: string; valueId: string }[] = [];
  const datasourceIdValues: { value: string; propertyId: string; searchId: string; valueId: string }[] = [];

  const search = sqItemFinderStore.searches.find((search) => search.searchId === searchId);
  const datasourceIdProperty = search?.predicates?.find(
    (predicate) => predicate.propertyName === SeeqNames.Properties.DatasourceId,
  );

  selectedDatasources.forEach((datasource) => {
    const { datasourceClass, datasourceId, name, id } = datasource.value;

    datasourceClassValues.push({
      value: datasourceClass as string,
      propertyId,
      searchId,
      valueId: base64guid(),
    });
    if (datasourceIdProperty) {
      datasourceIdValues.push({
        value: datasourceId as string,
        propertyId: datasourceIdProperty?.propertyId,
        searchId,
        valueId: base64guid(),
      });
    }
    datasourceLabel.push({
      label: datasource.value.name,
      value: {
        datasourceClass: datasourceClass as string,
        datasourceId: datasourceId as string,
        name,
        id: id as string,
      },
    });
  });

  // Update the property to contain the datasource id and name for the first selected datasource
  updateSearch({
    searchId,
    action: 'UPDATE_PROPERTY',
    propertyId,
    propertyData: {
      datasources: !selectedDatasources.length ? [] : datasourceLabel,
      values: !selectedDatasources.length ? [{ value: '', propertyId, searchId, valueId }] : datasourceClassValues,
    },
  });

  // If there is no datasourceId property, add it. Otherwise, update the values
  if (!datasourceIdProperty) {
    updateSearch({
      searchId,
      action: 'ADD_PROPERTY',
      propertyData: {
        propertyName: SeeqNames.Properties.DatasourceId,
        operator: OperatorEnum.STRINGCONTAINS,
      },
      propertyValues: selectedDatasourceIds,
    });
  } else {
    updateSearch({
      searchId,
      action: 'UPDATE_PROPERTY',
      propertyId: datasourceIdProperty.propertyId,
      propertyData: {
        values: !selectedDatasources.length
          ? [
              {
                value: '',
                propertyId: datasourceIdProperty.propertyId,
                searchId,
                valueId: datasourceIdProperty?.values[0]?.valueId,
              },
            ]
          : datasourceIdValues,
      },
    });
  }
};

export async function findDatasourceNamesAndIds(datasourceClass: string) {
  try {
    const { data } = await sqDatasourcesApi.getDatasources({ datasourceClass });
    if (data.datasources) {
      return {
        name: data.datasources[0].name,
        id: data.datasources[0].id,
        datasourceId: data.datasources[0].datasourceId,
      };
    }
  } catch (error) {
    errorToast({ httpResponseOrError: error });
  }
}

export const getItemFinder = async (itemFinderId: string): Promise<ItemFinderOutputV1 | undefined> => {
  try {
    const { data: itemFinderOutput } = await sqItemsApi.getItemFinder({ id: itemFinderId });
    return itemFinderOutput;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
};

export const addEmptyPropertyToSearch = (search: Search) => {
  if (
    search.searchType === SearchTypeEnum.PROPERTY ||
    ((search.searchType as SearchType) === SearchTypeEnum.WORKBENCH && !search.predicates)
  ) {
    updateSearch({
      searchId: search.searchId,
      action: 'ADD_PROPERTY',
    });
  }
};

export const isValidAIPayload = (payload: Record<string, unknown>): payload is AIPayload => {
  const { propertySearches, fixedListSearches } = payload;
  if (!propertySearches && !fixedListSearches) {
    return false;
  }
  if (propertySearches && Array.isArray(propertySearches)) {
    if (!propertySearches.every(isPropertySearch)) {
      return false;
    }
  }
  if (fixedListSearches && Array.isArray(fixedListSearches)) {
    if (!fixedListSearches.every(isFixedListSearch)) {
      return false;
    }
  }
  return true;
};

const isFixedListSearch = (search: unknown): search is FixedListSearchV1 => {
  const searchTypeCorrect = (search as models.FixedListSearchV1).searchType === SearchTypeEnum.FIXEDLIST;
  const isIncludeCorrect = typeof (search as models.FixedListSearchV1).isInclude === 'boolean';
  const itemIdsCorrect = Array.isArray((search as models.FixedListSearchV1).itemIds);
  return searchTypeCorrect && isIncludeCorrect && itemIdsCorrect;
};

const isPropertySearch = (search: unknown): search is PropertySearchV1 => {
  if (!search || typeof search !== 'object') {
    return false;
  }
  if ((search as PropertySearchV1).searchType !== SearchTypeEnum.PROPERTY) {
    return false;
  }
  if (!('searchType' in search) || !('isInclude' in search) || !('types' in search)) {
    return false;
  }

  if ('predicates' in search) {
    if (!Array.isArray((search as PropertySearchV1).predicates)) {
      return false;
    }
    if (!(search as PropertySearchV1).predicates?.every(isPropertyFilterInputV1)) {
      return false;
    }
  }
  return true;
};

const isPropertyFilterInputV1 = (predicate: unknown): predicate is PropertyFilterInputV1 => {
  if (!predicate || typeof predicate !== 'object') {
    return false;
  }
  if (!('operator' in predicate) || !('propertyName' in predicate) || !('values' in predicate)) {
    return false;
  }
  if (!Array.isArray((predicate as PropertyFilterInputV1).values)) {
    return false;
  }
  return (predicate as PropertyFilterInputV1).values.every(isPropertyInputV1);
};

const isPropertyInputV1 = (value: unknown): value is PropertyInputV1 => {
  if (!value || typeof value !== 'object') {
    return false;
  }
  return 'value' in value;
};

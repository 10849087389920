import React, { useEffect, useRef, useState } from 'react';
import { sqAxiosStore } from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { Icon } from '@seeqdev/qomponents';

interface LoadingSpinnerProps {
  top?: boolean;
}

/**
 * This component encapsulates the "loading indicator" spinner
 */
export const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({ top }) => {
  const { pendingRequests } = useFlux(sqAxiosStore);
  const nRequests = useRef(pendingRequests);
  const spinnerTimeout = useRef<number>();
  const [progress, setProgress] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (nRequests.current > pendingRequests) {
      if (pendingRequests === 0) {
        setProgress(100);
      } else {
        setProgress((1 / pendingRequests) * 100);
      }
    } else {
      if (pendingRequests === 1) {
        setProgress(50);
      }
    }
    nRequests.current = pendingRequests;
  }, [pendingRequests]);

  useEffect(() => {
    clearTimeout(spinnerTimeout.current);
    if (progress > 0 && progress < 100) {
      setShowSpinner(true);
    } else {
      spinnerTimeout.current = window.setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
    }
  }, [progress]);

  return showSpinner ? (
    <div className={`pl10 ${top ? 'loading-bar-spinner' : 'mtn1'}`}>
      <Icon type={top ? 'theme' : 'white'} icon="fc-loading-notch" extraClassNames="fa-spin" />
    </div>
  ) : null;
};

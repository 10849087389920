import React, { RefObject } from 'react';

const TableBuilderAgGridChartView = ({
  chartWrapperRef,
  showChartView,
}: {
  chartWrapperRef: RefObject<HTMLDivElement>;
  showChartView: boolean;
}) => {
  if (!showChartView) {
    return null;
  }

  return (
    <div
      ref={chartWrapperRef}
      id="tableChart"
      data-testid="tableChartView"
      className="ag-theme-quartz height-maximum pb5 screenshotSizeToContent"
    />
  );
};

export default TableBuilderAgGridChartView;

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classNames from 'classnames';
import type { AgGridReact } from '@ag-grid-community/react';
import {
  ColumnRowGroupChangedEvent,
  GridApi,
  IRowNode,
  IsGroupOpenByDefaultParams,
  RowDragEndEvent,
  RowGroupOpenedEvent,
  InitialGroupOrderComparatorParams,
} from '@ag-grid-community/core';
import { isPresentationWorkbookMode } from '@/utilities/utilities';
import { getStripedColor } from '@/utilities/tableBuilderHelper.utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { AgGridAsync } from '@/core/tableUtilities/AgGridAsync';
import useAgGridProps from './hooks/useAgGridProps';
import { TableBuilderSimpleAgGridProps } from './tableBuilder.types';
import TableBuilderAgGridChartView from './TableBuilderAgGridChartView.molecule';
import {
  COLUMN_HEADER_ID,
  DEFAULT_TABLE_ROW_HEIGHT,
  ROW_ID,
  SEEQ_AG_GRID_ID,
  SEEQ_ROW_INDEX,
  SIMPLE_TABLE_STRIPED_FIELD,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from './tableBuilder.constants';
import {
  createSimpleColDefs,
  getFullGroupedNodePath,
  getMoreRowsProps,
  getSimpleTableHeaderComponentParams,
  onRowDragEnd,
  simpleTableComparator,
} from './tableBuilderAgGrid.utilities';
import { COLUMNS_AND_STATS, TREND_COLORS } from '@/trendData/trendData.constants';

export const TableBuilderSimpleAgGrid: React.FunctionComponent<TableBuilderSimpleAgGridProps> = (
  props: TableBuilderSimpleAgGridProps,
) => {
  const {
    autoGroupColumn,
    simpleColumns,
    simpleTableData,
    isTransposed,
    isStriped,
    darkMode,
    setAgGridElement,
    updateContentMeasurements,
    moveColumn,
    rowGroupPaths,
    handleRowGroupOpened,
    handleRowGroupChanged,
    sortByColumn,
    showChartView,
    useSignalColorsInChart,
    chartColors,
  } = props;

  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rowNodeOutsideGridRef = useRef<IRowNode>();
  const [agGridApi, setAgGridApi] = useState<GridApi>();
  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const { t } = useTranslation();

  const chartViewColors = useMemo(() => {
    return useSignalColorsInChart && chartColors ? Object.values(chartColors) : TREND_COLORS;
  }, [chartColors, useSignalColorsInChart]);

  const nonStatisticIndexes = useMemo(() => {
    if (!showChartView) return [];

    return simpleColumns
      .map((col, colIndex) =>
        col.key.startsWith('statistic') || col.key === COLUMNS_AND_STATS.metricValue.key ? null : colIndex,
      )
      .filter((index) => index !== null);
  }, [showChartView, simpleColumns]);

  const setAgGridElementWrapper = (el: AgGridReact) => {
    if (setAgGridElement) {
      setAgGridElement(el);
    }
    if (wrapperRef.current) {
      (wrapperRef.current as any).__AG_GRID__ = el;
    }
  };

  const columnDefs = useMemo(
    () => createSimpleColDefs(props, showChartView),
    [showChartView, isTransposed, simpleColumns, isStriped, simpleTableData],
  );

  const normalRows = useMemo((): Record<string, any>[] => {
    const rowData: Record<string, any>[] = [];
    simpleTableData.forEach((row, rowIndex) => {
      const agGridRow: Record<string, any> = {};
      agGridRow[SIMPLE_TABLE_STRIPED_FIELD] = getStripedColor(isStriped, rowIndex, darkMode);

      const hasSourceItem = row.itemId !== row.formulaItemId;
      agGridRow[ROW_ID] = row.itemId + (hasSourceItem ? row.formulaItemId : rowIndex.toString());
      agGridRow[SEEQ_ROW_INDEX] = rowIndex;

      let columnIndex = 0;
      columnDefs.forEach((def) => {
        const column = simpleColumns[columnIndex];
        const cell = row.cells[columnIndex];

        const fieldName = def.field!;
        agGridRow[fieldName] = column?.type === TableBuilderColumnType.Text ? column?.cells?.[row.itemId] : cell;

        columnIndex++;
      });

      rowData.push(agGridRow);
    });

    return rowData;
  }, [darkMode, isStriped, simpleColumns, columnDefs, simpleTableData]);

  const transposedRows = useMemo((): Record<string, any>[] => {
    return simpleColumns
      .filter((_, columnIndex) => !nonStatisticIndexes.includes(columnIndex))
      .map((column, columnIndex) => {
        const agGridRow: Record<string, any> = {};
        agGridRow[ROW_ID] = column.key;

        _.forEach(simpleTableData, (row, rowIndex) => {
          const filteredCells = row.cells.filter((_, index) => !nonStatisticIndexes.includes(index));
          const cell = filteredCells[columnIndex];

          if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
            agGridRow[rowIndex.toString()] = column?.cells?.[row.itemId];
          } else {
            agGridRow[rowIndex.toString()] = cell;
          }
        });

        agGridRow[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN] = {
          headerComponentParams: getSimpleTableHeaderComponentParams(props, column, columnIndex, false),
        };

        agGridRow[COLUMN_HEADER_ID] = column.key;

        return agGridRow;
      });
  }, [darkMode, isStriped, simpleColumns, columnDefs, simpleTableData]);

  const data = isTransposed ? transposedRows : normalRows;

  const rowIds: Record<string, { index: number }> = data?.reduce((acc, row, index) => {
    acc[row[ROW_ID]] = { index };

    return acc;
  }, {} as Record<number, string>);

  /** --------- Charts -----------  */
  const shouldAutoSize = useMemo(
    () => agGridApi && !showChartView && simpleColumns.length > 0 && props?.rawSimpleTableData?.length > 0,
    [showChartView, simpleColumns, agGridApi, props?.rawSimpleTableData?.length],
  );

  const { autoSizeHelper, sharedAgGridProps, showTable } = useAgGridProps({
    agGridApi,
    chartWrapperRef,
    columnDefs,
    data,
    setAgGridApi,
    showChartView,
    shouldAutoSize,
    props,
    rowNodeOutsideGridRef,
    simpleTableData,
    t,
    tableBuilderMode: TableBuilderMode.Simple,
    wrapperRef,
    rowIds,
    chartViewColors,
  });

  /* AgGrid Handlers */
  const handleRowGroupOpenedChange = useCallback(
    (event: RowGroupOpenedEvent) => {
      handleRowGroupOpened(event);
      autoSizeHelper();
    },
    [handleRowGroupOpened],
  );

  const handleRowGroupChangedSimpleTable = useCallback(
    (event: ColumnRowGroupChangedEvent) => {
      handleRowGroupChanged(event);
      autoSizeHelper();
    },
    [handleRowGroupChanged],
  );

  const isGroupOpenByDefaultSimpleTable = useCallback(
    (params: IsGroupOpenByDefaultParams) => {
      if (!params?.rowNode.key) {
        return false;
      }
      return rowGroupPaths.includes(getFullGroupedNodePath(params.rowNode));
    },
    [rowGroupPaths],
  );

  const handleRowDragStop = useCallback(() => {
    if (rowNodeOutsideGridRef.current) {
      onRowDragEnd(rowNodeOutsideGridRef.current, simpleColumns, moveColumn, rowNodeOutsideGridRef);
    }
  }, [moveColumn]);

  const handleRowDragEnd = useCallback(
    (event: RowDragEndEvent) => {
      onRowDragEnd(event.node, simpleColumns, moveColumn, rowNodeOutsideGridRef);
    },
    [moveColumn],
  );

  return (
    <>
      <div
        ref={wrapperRef}
        id={SEEQ_AG_GRID_ID}
        data-testid="simpleTable"
        className={classNames('flexFillOverflow overflowAuto', {
          hidden: showChartView || (!showTable && !isInteractiveContent && !headlessRenderMode()),
        })}>
        <AgGridAsync
          className={classNames({ 'transposed-simple-table': isTransposed })}
          columnDefs={columnDefs}
          headerHeight={props.headers.type === TableBuilderHeaderType.None ? 0 : DEFAULT_TABLE_ROW_HEIGHT}
          isGroupOpenByDefault={isGroupOpenByDefaultSimpleTable}
          onColumnRowGroupChanged={handleRowGroupChangedSimpleTable}
          onDragStopped={handleRowDragStop}
          onRowDragEnd={handleRowDragEnd}
          onRowGroupOpened={handleRowGroupOpenedChange}
          ref={setAgGridElementWrapper}
          rowDragText={() => ''}
          rowGroupPanelShow={isTransposed || !autoGroupColumn ? 'never' : 'always'}
          suppressMaxRenderedRowRestriction={true}
          suppressRowVirtualisation={isPresentationWorkbookMode() && headlessRenderMode()}
          onSortChanged={(params) => {
            if (!isTransposed && params.source !== 'gridOptionsChanged' && params.columns) {
              const [sortedColumns, nonSortedColumns] = _.partition(params.columns, (column) => column.getSort());
              sortedColumns.forEach((column) => {
                sortByColumn(column.getColId(), column.getSort()!);
              });
              nonSortedColumns.forEach((column) => {
                sortByColumn(column.getColId(), undefined);
              });
            }
          }}
          alwaysMultiSort={true}
          {...sharedAgGridProps}
          {...getMoreRowsProps(data.length)}
        />
      </div>
      <TableBuilderAgGridChartView
        chartWrapperRef={chartWrapperRef}
        showChartView={!!simpleTableData?.length && showChartView}
      />
    </>
  );
};

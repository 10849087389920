import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { SelectOptionIF } from '@/usage/Usage.page';
import { Datasource } from '@/search/search.types';
import { useTranslation } from 'react-i18next';
import { Select } from '@seeqdev/qomponents';
import { buildSelectOptions } from '@/search/SearchFilters.molecule';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqSearchStore } from '@/core/core.stores';
import classNames from 'classnames';

export interface DatasourceSelectIF extends ValidatingFormComponent<any> {
  component: 'DatasourceSelectFormComponent';
  onChange: (selectedDatasources: SelectOptionIF<Datasource>[]) => void;
  placeholder: string;
  testId?: string;
  wrapperClasses?: string;
  label: string;
}

export const DatasourceSelectFormComponent: React.FunctionComponent<DatasourceSelectIF> = (props) => {
  const {
    name,
    validation,
    testId = 'datasourceSelect',
    wrapperClassNames,
    customErrorText,
    required = false,
    extendValidation,
    onChange,
    placeholder,
    label,
  } = props;

  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    ((formState.modified && formState.modified[name]) || formState.submitFailed);

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const { datasources, isUsingDatasourcePrefsSearchFilters } = useFlux(sqSearchStore);
  const datasourceOptions = buildSelectOptions(datasources, [], isUsingDatasourcePrefsSearchFilters);

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames={classNames('flexFill', wrapperClassNames)}
        testId={testId}
        showError={showError}
        errorClassNames="ml20"
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const { value } = getFormFieldProps(formState, input, meta, props);
            const filteredDatasources = [...datasourceOptions].filter(({ label: datasourceLabel }) => {
              if (Array.isArray(value)) {
                const labelsToExclude = value.map(({ label }: { label: string }) => label);
                return !labelsToExclude.includes(datasourceLabel);
              }
              return true;
            });

            return (
              <div>
                <div data-testid="datasourceSelectInput" className="multiSelectLarge">
                  <label className="mb6"> {t(label)}</label>
                  <Select
                    isMulti={true}
                    isClearable={true}
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    options={filteredDatasources}
                    menuPortalTarget={document.body}
                    placeholder={t(placeholder)}
                    value={value}
                    showError={showError}
                    onChange={(selectedDatasources: SelectOptionIF<Datasource>[]) => {
                      onChange(selectedDatasources);
                    }}
                  />
                </div>
              </div>
            );
          }}
        </Field>
      </FormFieldWrapper>
    </>
  );
};

import _ from 'lodash';
import { INTERACTIVE_CONTENT_WORKSTEP_VERSION } from '@/reportEditor/report.constants';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { ChartSettingsInterface, TableBuilderPropsOnlyProps } from '@/tableBuilder/TableBuilderPropsOnly.organism';
import { validateGuid } from '@/utilities/utilities';
import { isStartOrEndColumn } from '@/utilities/tableBuilderHelper.utilities';
import { ColumnOrRowWithDefinitions, ConditionTableColumnsAndRows } from '@/tableBuilder/tableBuilder.types';
import { withDefaultFormatting } from '@/tableBuilder/tableBuilder.constants';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import type { CapsuleChartItem, CapsuleItem } from '@/trendData/trendData.types';

/**
 * Upgrade the state of an interactive content property map from the specified version to the latest version. Runs the
 * map through a series of transform functions, in order, from the map's version to the specified version.
 *
 * @param properties - The content properties
 * @param fromVersion - The version number from when the blob was created
 * @param [toVersion] - The version number up to which the blob will be migrated. Useful only for testing.
 * @returns The transformed blob.
 */
export function applyContentUpgrade(
  properties: any,
  fromVersion: number,
  toVersion = INTERACTIVE_CONTENT_WORKSTEP_VERSION,
): any {
  const upgradedProperties = _.reduce(
    _.range(fromVersion, toVersion, 1),
    (newProperties, newVersion) => {
      const upgraderName = `upgrade${newVersion}`;
      const upgrader = upgraders[upgraderName];
      // Subtle difference from workstepUpgrader::apply, we don't require upgrades for every workstep version
      return upgrader ? upgrader(newProperties) : newProperties;
    },
    properties,
  );
  upgradedProperties.version = INTERACTIVE_CONTENT_WORKSTEP_VERSION;
  return upgradedProperties;
}

/**
 * Assigns lanes to conditions and shift other item lanes.
 * Modified from upgrade workstepUpgrade::upgrade50
 *
 * @param untypedProperties
 */
function upgrade50(untypedProperties: any) {
  if (untypedProperties.visualization !== Visualization.TREND) {
    return untypedProperties;
  }

  const properties = untypedProperties;
  const conditionItems = _.sortBy(properties?.data?.sqTrendCapsuleSetStoreData?.items ?? [], 'conditionLane');

  if (_.some(conditionItems, (condition) => condition.lane)) {
    // Conditions already have assigned lane property, so we can leave early.
    return untypedProperties;
  }

  let conditionsEndLane = 0;
  const conditionIdToLane = _.map(conditionItems, (item, index) => {
    const newLane = +index + 1;
    item.lane = newLane;
    conditionsEndLane = newLane;
    if (_.has(item, 'conditionLane')) {
      delete item.conditionLane;
    }
    return { id: item.id, lane: newLane };
  });

  if (conditionsEndLane === 0) {
    return properties;
  }

  (properties.data.sqTrendCapsuleSetStoreData as any).items = conditionItems;

  properties.data.items = _.map(properties.data.items, (item) => {
    // Gets certain and uncertain capsules
    if (item.capsuleSetId) {
      const conditionId = item.capsuleSetId.includes('_')
        ? item.capsuleSetId.substring(0, item.capsuleSetId.indexOf('_'))
        : item.capsuleSetId;
      item.lane = _.find(conditionIdToLane, { id: conditionId })?.lane;
    } else if (_.isInteger(item.lane)) {
      item.lane += conditionsEndLane;
    }
    return item;
  });

  properties.data.lanes = _.chain(properties.data.items)
    .map((item) => item.lane)
    .uniq()
    .value()
    .sort();

  return properties;
}

const TABLE_CHART_COLUMN_PROPS = ['columns', 'categoryColumns'] as const;

/**
 * Changes table builder chart columns to reference the column based on name instead of index.
 * Modified from upgrade workstepUpgrade::upgrade51
 *
 * @param untypedProperties
 */
function upgrade51(untypedProperties: any): any {
  if (untypedProperties.visualization !== Visualization.TABLE) {
    return untypedProperties;
  }
  const properties = untypedProperties as TableBuilderPropsOnlyProps;
  if (properties.chartViewSettings) {
    const chartViewSettings = properties.chartViewSettings as ChartSettingsInterface;
    _.forEach(TABLE_CHART_COLUMN_PROPS, (setting) => {
      if (_.isArray(chartViewSettings[setting])) {
        _.forEach(chartViewSettings[setting], (columnIndex, index) => {
          if (_.isNumber(columnIndex) && properties.simpleColumns[columnIndex]) {
            chartViewSettings[setting].splice(index, 1, properties.simpleColumns[columnIndex].key);
          }
        });
      }
    });
    // Old version of rows was indices that pointed to items in the details pane. Since the details pane cannot be
    // reconstructed the setting is emptied.
    if (_.every(chartViewSettings.rows, _.isNumber)) {
      chartViewSettings.rows = [];
    }

    _.forEach(properties.simpleTableData, (data) => {
      _.forEach(data.cells, (cell) => {
        if (!cell.rawValue) {
          const maybeNumber = Number(cell.value);
          cell.rawValue = _.isNaN(maybeNumber) ? cell.value : maybeNumber;
        }
      });
    });
  }

  return properties;
}

function upgrade55(untypedProperties: any): TableBuilderPropsOnlyProps {
  const { visualization, conditionColumns, conditionTableData, itemSorts, itemFilters } = untypedProperties;
  if (visualization !== Visualization.TABLE) {
    return untypedProperties;
  }
  const newConditionColumns: ConditionTableColumnsAndRows = {
    columns: conditionColumns.columns,
    rows: conditionColumns.propertyAndStatColumns,
  };

  // Add metrics before any property or stat
  const metricHeaders = conditionTableData.headers.filter((header: any) => validateGuid(header.key));
  // The rows field is only properties/stats/and metrics so we can just put these in the front
  newConditionColumns.rows = metricHeaders
    .map((mh: any) => {
      const metricColumn = {
        key: mh.key,
        metricId: mh.key,
      };
      const metricColumnWithSort = itemSorts?.[mh.key]
        ? { ...metricColumn, sort: itemSorts[mh.key].sort }
        : metricColumn;
      const metricColumnWithFilter = itemFilters?.[mh.key]
        ? { ...metricColumnWithSort, filter: itemFilters[mh.key].filter }
        : metricColumnWithSort;
      return withDefaultFormatting(metricColumnWithFilter);
    })
    .concat(newConditionColumns.rows);

  // Add Start/end in their current relative order in front of any metric or property or stat
  const [startAndEndRows, allOtherRows] = _.partition(newConditionColumns.rows, (row) => isStartOrEndColumn(row));
  newConditionColumns.rows = startAndEndRows.concat(allOtherRows);

  const rowIdCount = new Map<string, number>();
  untypedProperties.conditionTableData.capsules = untypedProperties.conditionTableData.capsules.map((capsule: any) => {
    let rowId = `capsule-${capsule.startTime}-${capsule.endTime}`;
    rowIdCount.set(rowId, (rowIdCount.get(rowId) ?? 0) + 1);
    if (rowIdCount.get(rowId)! > 1) {
      rowId = `${rowId}-${rowIdCount.get(rowId)}`;
    }

    return {
      ...capsule,
      id: rowId,
    };
  });

  return {
    ...untypedProperties,
    conditionColumns: newConditionColumns,
    tableItems: metricHeaders.map((m: any) => ({ id: m.key, itemType: ITEM_TYPES.METRIC })),
  };
}

/**
 * Flattens condition table filter and sort properties for metric columns.
 * This fixes state that upgrade55 transformed into a broken format (CRAB-41147)
 *
 * @param untypedProperties
 */
function upgrade56(untypedProperties: any): TableBuilderPropsOnlyProps {
  const { visualization } = untypedProperties;
  if (visualization !== Visualization.TABLE) {
    return untypedProperties;
  }

  (untypedProperties.conditionColumns.rows as any[])
    .filter((column) => column.filter?.filter)
    .forEach((column) => {
      column.filter = column.filter.filter;
    });

  (untypedProperties.conditionColumns.rows as any[])
    .filter((column) => column.sort?.sort)
    .forEach((column) => {
      column.sort = column.sort.sort;
    });

  return untypedProperties;
}

/**
 * Changes sqTableCapsuleSetStore to sqTrendConditionStore
 * Modified from upgrade workstepUpgrade::upgrade61
 *
 * @param untypedProperties
 */
function upgrade61(untypedProperties: any): any {
  const properties = untypedProperties;
  const sqTrendConditionStoreData = _.get(properties, 'data.sqTrendConditionStoreData');

  if (!_.isNil(sqTrendConditionStoreData)) {
    // sqTrendConditionStoreData already exists, so we can leave early.
    return untypedProperties;
  }

  if (properties.data) {
    properties.data.sqTrendConditionStoreData = _.get(properties, 'data.sqTrendCapsuleSetStoreData', {});
    delete properties.data.sqTrendCapsuleSetStoreData;
  }

  return properties;
}

/**
 * Adds a dummy autoHeight property for table stores if it is not present
 *
 * @param untypedProperties
 */
function upgrade62(untypedProperties: any): any {
  const properties = untypedProperties;
  if (properties.visualization === Visualization.TABLE && properties.columnIdToAutoHeight === undefined) {
    properties.columnIdToAutoHeight = {};
  }

  return properties;
}

/**
 * Replaces all usages of capsuleSet* with condition*
 */
function upgrade66(untypedProperties: any): any {
  interface OldCapsuleProps {
    capsuleSetId: string;
    capsuleSetName: string;
  }

  const properties = untypedProperties;
  if (properties.visualization === Visualization.TREND) {
    properties.data.items = properties.data.items.map((item: CapsuleChartItem & OldCapsuleProps) => {
      item.conditionId = item.capsuleSetId;
      item.conditionName = item.capsuleSetName;

      _.unset(item, 'capsuleSetId');
      _.unset(item, 'capsuleSetName');

      return item;
    });

    properties.data.capsules = properties.data.capsules.map((capsule: CapsuleItem & OldCapsuleProps) => {
      capsule.conditionId = capsule.capsuleSetId;
      capsule.conditionName = capsule.capsuleSetName;

      _.unset(capsule, 'capsuleSetId');
      _.unset(capsule, 'capsuleSetName');

      return capsule;
    });
  }

  return properties;
}

/**
 * Migrate sqTableBuilderStore Highcharts Simple Tables to AgGrid Charts
 *
 * @param untypedProperties
 */
function upgrade67(untypedProperties: any) {
  if (untypedProperties.visualization !== Visualization.TABLE) {
    return untypedProperties;
  }

  const chartViewEnabled = _.get(untypedProperties, 'showChartView');
  const cellRange = _.get(untypedProperties, 'chartViewSettings.cellRange');

  if (!chartViewEnabled || !!cellRange) {
    return untypedProperties;
  }

  const chartSettings = _.get(untypedProperties, 'chartViewSettings');
  const currentColumns: ColumnOrRowWithDefinitions[] = _.get(untypedProperties, 'simpleColumns', []);
  const categoryColumns: string[] = _.get(untypedProperties, 'chartViewSettings.categoryColumns', []);
  const columns: string[] = _.get(untypedProperties, 'chartViewSettings.columns', []);
  const currentTransposed: boolean = _.get(untypedProperties, 'isTransposed');
  const oldChartType: string = _.get(untypedProperties, 'chartViewSettings.chartType');

  let newChartType;
  if (oldChartType === 'column_stacked') {
    newChartType = 'stackedColumn';
  } else if (oldChartType === 'bar_stacked') {
    newChartType = 'stackedBar';
  } else {
    newChartType = oldChartType;
  }

  _.set(untypedProperties, 'isTransposed', oldChartType !== 'pie' ? !currentTransposed : currentTransposed);

  _.set(untypedProperties, 'chartViewSettings', {
    ...chartSettings,
    chartType: newChartType,
  });

  const defaultRange = columns.length ? [...categoryColumns, ...columns] : [];
  if (currentTransposed && oldChartType !== 'pie') {
    _.set(untypedProperties, 'chartViewSettings.cellRange', {
      columns: defaultRange?.length ? defaultRange : currentColumns.map((col) => col.key),
    });
  } else {
    const newRange = defaultRange.length
      ? currentColumns.filter((col) => defaultRange.includes(col.key))
      : currentColumns;

    _.set(untypedProperties, 'simpleColumns', newRange);
    _.set(untypedProperties, 'chartViewSettings.cellRange', {
      columns: newRange.map((col) => col.key),
    });
  }

  _.set(untypedProperties, 'chartViewSettings.chartOptions', {
    common: {
      axes: {
        category: {
          label: { enabled: chartSettings?.categoryLabels },
        },
      },
      legend: {
        enabled: chartSettings?.legend,
      },
      series: {
        label: {
          enabled: chartSettings?.dataLabels,
        },
      },
    },
  });

  const keysToRemove = [
    'categoryColumns',
    'categoryLabels',
    'columns',
    'dataLabels',
    'legend',
    'position',
    'rows',
    'showSettings',
    'showHeader',
    'title',
  ];

  keysToRemove.forEach((key) => {
    _.unset(untypedProperties, `chartViewSettings.${key}`);
  });

  return untypedProperties;
}

const upgraders: Record<string, (properties: any) => any> = {
  upgrade50,
  upgrade51,
  upgrade55,
  upgrade56,
  upgrade61,
  upgrade62,
  upgrade66,
  upgrade67,
};

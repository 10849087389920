import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PUSH_WORKBENCH } from '@/core/flux.service';
import { AgentType, AnswerStyle, ChatMessage, FontSize, ReasoningStyle } from '@/aiAssistant/aiAssistant.types';

export function setAIAssistantDisplayed(displayed: boolean, ignore = false) {
  flux.dispatch('AI_ASSISTANT_DISPLAYED', { displayed }, ignore ? PUSH_IGNORE : PUSH_WORKBENCH);
}

export function setAIAssistantMaximized(maximized: boolean) {
  flux.dispatch('AI_ASSISTANT_MAXIMIZED', { maximized }, PUSH_WORKBENCH);
}

export function setAIAssistantMessages(messages: ChatMessage[]) {
  flux.dispatch('AI_ASSISTANT_SET_MESSAGES', { messages }, PUSH_WORKBENCH);
}

export function setSelectedAgent(agent: AgentType) {
  flux.dispatch('AI_ASSISTANT_SET_SELECTED_AGENT', { agent }, PUSH_WORKBENCH);
}

export function setAIAssistantFontSize(fontSize: FontSize) {
  flux.dispatch('AI_ASSISTANT_SET_FONT_SIZE', { fontSize }, PUSH_WORKBENCH);
}
export function setAIAssistantExternalPrompt(payload: { prompt: string; agent?: AgentType } | undefined) {
  flux.dispatch('AI_ASSISTANT_EXTERNAL_PROMPT', payload);
  if (payload?.agent) {
    setSelectedAgent(payload.agent);
  }
}
export function setAIAssistantAnswerStyle(answerStyle: AnswerStyle) {
  flux.dispatch('AI_ASSISTANT_SET_ANSWER_STYLE', { answerStyle }, PUSH_WORKBENCH);
}

export function setAIAssistantReasoningStyle(reasoningStyle: ReasoningStyle) {
  flux.dispatch('AI_ASSISTANT_SET_REASONING_STYLE', { reasoningStyle }, PUSH_WORKBENCH);
}

export function setAiAssistantRefreshHistory(refreshHistory: boolean) {
  flux.dispatch('AI_ASSISTANT_SET_REFRESH_HISTORY', { refreshHistory });
}

export function setAiAssistantActiveChat(chat: { id: string; name: string } | undefined) {
  flux.dispatch('AI_ASSISTANT_SET_ACTIVE_CHAT', { chat });
}

export function setAiAssistantHistorySearch(searchValue: string) {
  flux.dispatch('AI_ASSISTANT_SET_HISTORY_SEARCH', { searchValue }, PUSH_WORKBENCH);
}

import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useCounter } from 'rooks';
import { useTranslation } from 'react-i18next';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import type { AgGridReact } from '@ag-grid-community/react';
import {
  ColumnRowGroupChangedEvent,
  GridApi,
  IRowNode,
  IsGroupOpenByDefaultParams,
  RowDragEndEvent,
  RowGroupOpenedEvent,
  InitialGroupOrderComparatorParams,
} from '@ag-grid-community/core';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { getTextValueForConditionHeader, isStartOrEndColumn } from '@/utilities/tableBuilderHelper.utilities';
import { AlertWarning } from '@/trend/trendHelp/AlertWarning';
import { md5Hash } from '@/utilities/utilities';
import { AgGridAsync } from '@/core/tableUtilities/AgGridAsync';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import useAgGridProps from './hooks/useAgGridProps';
import {
  COLUMN_HEADER_ID,
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  DEFAULT_TABLE_ROW_HEIGHT,
  MAX_CONDITION_TABLE_CAPSULE_COLUMNS,
  ROW_ID,
  SEEQ_AG_GRID_ID,
  SEEQ_ROW_INDEX,
  TableBuilderHeaderType,
  TableBuilderMode,
} from './tableBuilder.constants';
import TableBuilderAgGridChartView from './TableBuilderAgGridChartView.molecule';
import {
  conditionTableComparator,
  createConditionColDefs,
  getConditionCapsuleFieldName,
  getConditionColumnFieldName,
  getConditionTableDragColumns,
  getConditionTextHeaderParams,
  getConditionTextHeaderParamsForSpecialColumns,
  getFullGroupedNodePath,
  getMoreRowsProps,
  onRowDragEnd,
} from './tableBuilderAgGrid.utilities';
import {
  ColumnOrRowWithDefinitions,
  ConditionTableHeader,
  TableBuilderConditionAgGridProps,
} from './tableBuilder.types';

export const TableBuilderConditionAgGrid: React.FunctionComponent<TableBuilderConditionAgGridProps> = (props) => {
  const {
    tableData,
    columns: conditionColumns,
    headers,
    isTransposed,
    isStriped,
    updateContentMeasurements,
    setAgGridElement,
    hasMoreData,
    setHideColumnHeadersWhenCopying,
    autoGroupColumn,
    handleRowGroupOpened,
    handleRowGroupChanged,
    moveColumn,
    rowGroupPaths,
    simpleColumns,
    showConditionChartView,
    sortByColumn,
  } = props;
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const [agGridApi, setAgGridApi] = useState<GridApi>();
  const rowNodeOutsideGridRef = useRef<IRowNode>();

  const { rows, columns } = conditionColumns;
  const showCapsuleHeaders = headers?.type !== TableBuilderHeaderType.None;
  const showTableHeaders = !!columns.find((column) => column.key === COLUMNS_AND_STATS.name.key);
  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const isContent = isInteractiveContent || headlessRenderMode();
  const hideHeaderRow = (!showTableHeaders && isTransposed) || (!showCapsuleHeaders && !isTransposed);
  const columnDefs = useMemo(
    () => createConditionColDefs(props, showCapsuleHeaders, hideHeaderRow, showConditionChartView),
    [showConditionChartView, isTransposed, isStriped, conditionColumns, tableData, headers],
  );

  const setAgGridElementWrapper = (el: AgGridReact) => {
    if (setAgGridElement) {
      setAgGridElement(el);
    }
    if (wrapperRef.current) {
      (wrapperRef.current as any).__AG_GRID__ = el;
    }
  };

  // We need to generate unique ids if the order or a sort changes
  const partialId = md5Hash({
    isTransposed,
    columns: columns.map((column, index) => ({ key: column.key, sort: column.sort, index })),
    rows: rows.map((row, index) => ({ key: row.key, sort: row.sort, index })),
  });

  const { value: tableDataCounterValue, increment: incrementTableDataCounter } = useCounter(0);

  useEffect(() => {
    incrementTableDataCounter();
  }, [incrementTableDataCounter, tableData]);

  const buildNonCapsuleRowId = useCallback(
    (columnOrHeader: ColumnOrRowWithDefinitions | ConditionTableHeader) => {
      return `${columnOrHeader.key}_${tableDataCounterValue}_${partialId}`;
    },
    [tableDataCounterValue, partialId],
  );

  const transposedRows = useMemo((): Record<string, any>[] => {
    // The column label row is embedded in the column headers, so we skip it if it is included
    const neededColumns = showConditionChartView ? [] : columns.slice(1);
    const currentColumns = showTableHeaders ? neededColumns : columns;
    let rowIndex = 0;
    const nonCapsuleRowData = currentColumns.map((column, columnIndexMaybeMinus1) => {
      const columnIndex = showTableHeaders ? columnIndexMaybeMinus1 + 1 : columnIndexMaybeMinus1;
      const row: Record<string, any> = {
        [COLUMN_HEADER_ID]: column.key,
        [ROW_ID]: buildNonCapsuleRowId(column),
        [SEEQ_ROW_INDEX]: rowIndex,
      };
      rowIndex += 1;
      if (showCapsuleHeaders) {
        const textHeaderProps = getConditionTextHeaderParamsForSpecialColumns(column, columnIndex, props);
        row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = {
          textHeaderProps,
        };
      }
      _.forEach(tableData.headers, (header) => {
        row[header.key] = getTextValueForConditionHeader(header, column);
      });

      return row;
    });

    const capsuleRowData = tableData.capsules.map((capsule) => {
      const row: Record<string, any> = {
        [ROW_ID]: capsule.id + partialId,
        [SEEQ_ROW_INDEX]: rowIndex,
      };
      rowIndex += 1;

      // Cell for capsule start/end
      row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = capsule;

      // Cells for each property or statistic for the capsule
      _.forEach(capsule.values, (value, valueIndex) => {
        const header = tableData.headers[valueIndex];
        row[header.key] = isStartOrEndColumn(header) ? capsule : value;
      });

      return row;
    });

    // This will ensure the capsule rows are always under the others
    return nonCapsuleRowData.concat(capsuleRowData);
  }, [columns, showCapsuleHeaders, isStriped, showTableHeaders, tableData, headers, showConditionChartView]);

  const normalRows = useMemo((): Record<string, any>[] => {
    const rowData: Record<string, any>[] = [];
    tableData.headers.forEach((header, rowIndex) => {
      if (
        showConditionChartView &&
        !header?.key?.startsWith('statistic') &&
        header?.key !== COLUMNS_AND_STATS.metricValue.key
      ) {
        return;
      }

      let columnIndex = 0;
      const row: Record<string, any> = {
        [ROW_ID]: buildNonCapsuleRowId(header),
      };
      const propertyOrStat = _.find(rows, {
        key: header.key,
        isPropertyOrStatColumn: true,
      });
      _.forEach(columns, (column) => {
        const fieldName = getConditionColumnFieldName(columnIndex);
        row[fieldName] =
          column.key === COLUMNS_AND_STATS.name.key
            ? {
                textHeaderProps: getConditionTextHeaderParams(header, column, propertyOrStat, rowIndex, props, false),
              }
            : getTextValueForConditionHeader(header, column);
        columnIndex++;
      });
      tableData.capsules.slice(0, MAX_CONDITION_TABLE_CAPSULE_COLUMNS).forEach((capsule, index) => {
        const fieldName = getConditionCapsuleFieldName(index);
        row[fieldName] = isStartOrEndColumn(header) ? capsule : capsule.values[rowIndex];
        columnIndex++;
      });

      row[COLUMN_HEADER_ID] = header.key;

      rowData.push(row);
    });

    return rowData.filter(Boolean);
  }, [columns, rows, tableData, isStriped, headers, showConditionChartView]);

  const data = isTransposed ? transposedRows : normalRows;

  const rowIds: Record<string, { index: number }> = data?.reduce((acc, row, index) => {
    acc[row[ROW_ID]] = { index };

    return acc;
  }, {} as Record<number, string>);

  const shouldAutoSize = useMemo(
    () =>
      agGridApi &&
      !showConditionChartView &&
      simpleColumns.length > 0 &&
      tableData.headers.length === rows.length &&
      !showConditionChartView,
    [showConditionChartView, tableData, simpleColumns, agGridApi, rows],
  );

  const totalRegularColumns = isTransposed ? 0 : tableData.capsules.length + columns.length;
  const [headerHeight, setHeaderHeight] = useState<number>();
  useEffect(() => {
    setHideColumnHeadersWhenCopying && setHideColumnHeadersWhenCopying(hideHeaderRow);
    setHeaderHeight(hideHeaderRow ? 0 : DEFAULT_TABLE_ROW_HEIGHT);
  }, [hideHeaderRow, setHideColumnHeadersWhenCopying]);

  /** --------- Charts -----------  */
  const { autoSizeHelper, sharedAgGridProps, showTable } = useAgGridProps({
    agGridApi,
    chartWrapperRef,
    columnDefs,
    conditionTableData: tableData,
    data,
    props,
    rowIds,
    rowNodeOutsideGridRef,
    setAgGridApi,
    showChartView: showConditionChartView,
    shouldAutoSize,
    t,
    tableBuilderMode: TableBuilderMode.Condition,
    wrapperRef,
    conditionColumns,
  });

  /* AgGrid Handlers */
  const handleRowDragEnd = (event: RowDragEndEvent) => {
    onRowDragEnd(
      event.node,
      getConditionTableDragColumns(conditionColumns, isTransposed),
      moveColumn,
      rowNodeOutsideGridRef,
    );
  };

  const handleDragStopped = () => {
    if (rowNodeOutsideGridRef.current) {
      onRowDragEnd(
        rowNodeOutsideGridRef.current,
        getConditionTableDragColumns(conditionColumns, isTransposed),
        moveColumn,
        rowNodeOutsideGridRef,
      );
    }
  };

  const handleRowGroupOpenedEvent = (event: RowGroupOpenedEvent) => {
    handleRowGroupOpened(event);
    autoSizeHelper();
  };

  const handleRowGroupChangedEvent = (event: ColumnRowGroupChangedEvent) => {
    if (conditionColumns.rows.every((r) => tableData.headers.some((h) => r.key === h.key))) {
      handleRowGroupChanged(event);
      autoSizeHelper();
    }
  };

  const isGroupOpenByDefault = (params: IsGroupOpenByDefaultParams) => {
    if (!params?.rowNode.key) {
      return false;
    }
    return rowGroupPaths.includes(getFullGroupedNodePath(params.rowNode));
  };

  return (
    <>
      {totalRegularColumns > MAX_CONDITION_TABLE_CAPSULE_COLUMNS && (
        <AlertWarning>{t('TABLE_BUILDER.CAPSULE_COLUMNS_LIMITED')}</AlertWarning>
      )}
      {isTransposed && hasMoreData && <AlertWarning>{t('TABLE_BUILDER.CAPSULE_ROWS_LIMITED')}</AlertWarning>}
      <div
        ref={wrapperRef}
        id={SEEQ_AG_GRID_ID}
        data-testid="conditionTable"
        className={classnames('flexFillOverflow overflowAuto', {
          'd-none': (!showTable && !isContent) || showConditionChartView,
        })}>
        <AgGridAsync
          ref={setAgGridElementWrapper}
          headerHeight={headerHeight}
          columnDefs={columnDefs}
          onRowDragEnd={handleRowDragEnd}
          onDragStopped={handleDragStopped}
          onRowGroupOpened={handleRowGroupOpenedEvent}
          onColumnRowGroupChanged={handleRowGroupChangedEvent}
          isGroupOpenByDefault={isGroupOpenByDefault}
          rowGroupPanelShow={isTransposed && autoGroupColumn ? 'always' : 'never'}
          onSortChanged={(params) => {
            if (params.source !== 'gridOptionsChanged' && params.columns) {
              const [sortedColumns, nonSortedColumns] = _.partition(params.columns, (column) => column.getSort());
              sortedColumns.forEach((column) => {
                sortByColumn(column.getColId(), column.getSort()!);
              });
              nonSortedColumns.forEach((column) => {
                sortByColumn(column.getColId(), undefined);
              });
            }
          }}
          alwaysMultiSort={true}
          {...sharedAgGridProps}
          {...getMoreRowsProps(data.length)}
        />
      </div>
      <TableBuilderAgGridChartView
        chartWrapperRef={chartWrapperRef}
        showChartView={!!tableData?.capsules?.length && showConditionChartView}
      />
    </>
  );
};

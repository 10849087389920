import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDebounce } from '@/core/hooks/useDebounce.hook';
import { AgGridModules } from '@/core/tableUtilities/tableUtilities.types';
import {
  addScreenshotSetup,
  autoSizeColumns,
  conditionTableComparator,
  firstNonEmptyValueAggFunc,
  getDomLayout,
  getRowId,
  lastNonEmptyValueAggFunc,
  onColumnMoved,
  rangeAggFunc,
  resizeAgGridHelper,
  restrictRowDataIfNecessary,
  simpleTableComparator,
  standardDeviationAggFunc,
} from '@/tableBuilder/tableBuilderAgGrid.utilities';
import {
  ChartToolPanelsDef,
  ColDef,
  Column,
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  InitialGroupOrderComparatorParams,
  IRowNode,
} from '@ag-grid-community/core';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { COLUMNS_AND_STATS, TREND_COLORS } from '@/trendData/trendData.constants';
import { GroupingHeader } from '@/tableBuilder/tableComponents/GroupingHeader.molecule';
import {
  AUTO_GROUP_COL_DEF,
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  DEFAULT_COL_DEF,
  DEFAULT_TABLE_ROW_HEIGHT,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '../tableBuilder.constants';
import { onColumnResized } from '../tableBuilderAgGrid.actions';
import {
  chartToolPanelsDef,
  getGridOptions,
  getSeeqAgChartTheme,
  persistChart,
  regenerateChart,
  SINGLE_SERIES_CHART_TYPES,
} from '../tableBuilderAgChart.utilities';
import {
  ConditionTableColumnsAndRows,
  ConditionTableData,
  ConditionTableHeader,
  SimpleTableRow,
  TableBuilderConditionAgGridProps,
  TableBuilderSimpleAgGridProps,
} from '../tableBuilder.types';
import { useResizeGroupingBar } from '../hooks/useResizeGroupingBar.hook';

interface IUseAgGridProps {
  agGridApi: GridApi<any> | undefined;
  columnDefs: ColDef[];
  chartWrapperRef: MutableRefObject<HTMLDivElement | null>;
  data: Record<string, any>[];
  props: TableBuilderConditionAgGridProps | TableBuilderSimpleAgGridProps;
  rowNodeOutsideGridRef: MutableRefObject<IRowNode<any> | undefined>;
  setAgGridApi: (api: GridApi) => void;
  showChartView: boolean;
  t: (key: string) => string;
  tableBuilderMode: TableBuilderMode;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  conditionTableData?: ConditionTableData;
  shouldAutoSize?: boolean;
  simpleTableData?: SimpleTableRow[];
  rowIds?: Record<number, { index: number }>;
  chartViewColors?: string[] | null;
  conditionColumns?: ConditionTableColumnsAndRows;
}

interface IAgGridProps {
  aggFuncs: Record<string, any>;
  animateRows: boolean;
  autoGroupColumnDef?: ColDef<any>;
  chartThemes: string[];
  chartToolPanelsDef: ChartToolPanelsDef;
  customChartThemes: Record<string, any>;
  defaultColDef: ColDef;
  domLayout: 'print' | 'autoHeight' | 'normal';
  enableCharts: boolean;
  functionsReadOnly: boolean;
  getRowId: (data: any) => string;
  gridOptions: GridOptions;
  groupAllowUnbalanced: boolean;
  initialGroupOrderComparator: ((params: InitialGroupOrderComparatorParams) => number) | undefined;
  modules: AgGridModules[];
  onChartOptionsChanged: () => void;
  onChartRangeSelectionChanged: () => void;
  onNewColumnsLoaded: () => void;
  onColumnMoved: (event: any) => void;
  onColumnResized: ((event: ColumnResizedEvent<any, any>) => void) | undefined;
  onGridReady: (params: GridReadyEvent) => void;
  onRowDragLeave: (event: any) => void;
  overlayNoRowsTemplate: string;
  popupParent: HTMLElement | null;
  rowData: any[];
  rowDragManaged: boolean;
  rowHeight: number;
  rowSelection: 'multiple';
  suppressAggFuncInHeader: boolean;
  suppressColumnVirtualisation: boolean;
  suppressContextMenu: boolean;
  suppressDragLeaveHidesColumns: boolean;
  suppressFieldDotNotation: boolean;
}

interface IUseAgGridReturn {
  autoSizeHelper: () => void;
  sharedAgGridProps: IAgGridProps;
  showTable: boolean;
}

const CHART_THEMES = ['seeq-theme'];
const CONTENT_CHART_TOOLS_PANEL_DEF = { panels: [] };

const getChartViewSettings = (
  tableBuilderMode: TableBuilderMode,
  props: TableBuilderSimpleAgGridProps | TableBuilderConditionAgGridProps,
) => {
  switch (tableBuilderMode) {
    case TableBuilderMode.Simple:
      return (props as TableBuilderSimpleAgGridProps).chartViewSettings;
    case TableBuilderMode.Condition:
      return (props as TableBuilderConditionAgGridProps).chartViewConditionSettings;
    default:
      return null;
  }
};

const getAutoGroupByType = (tableBuilderMode: TableBuilderMode, columnDefs: ColDef[], t: (key: string) => string) => {
  const BASE_AUTO_GROUP_COL_DEF = {
    ...AUTO_GROUP_COL_DEF,
    headerName: t('TABLE_BUILDER.ENABLE_GROUPING_BAR'),
    headerComponent: GroupingHeader,
  };

  switch (tableBuilderMode) {
    case TableBuilderMode.Simple:
      return { ...BASE_AUTO_GROUP_COL_DEF };
    case TableBuilderMode.Condition:
      return {
        ...BASE_AUTO_GROUP_COL_DEF,
        ..._.pick(columnDefs[0], 'valueFormatter', 'valueGetter', 'field'),
      };
    default:
      return { ...BASE_AUTO_GROUP_COL_DEF };
  }
};

const useAgGridProps = ({
  agGridApi,
  chartWrapperRef,
  columnDefs,
  conditionTableData,
  data,
  props,
  setAgGridApi,
  shouldAutoSize,
  showChartView,
  simpleTableData,
  t,
  tableBuilderMode,
  rowNodeOutsideGridRef,
  wrapperRef,
  rowIds = [],
  chartViewColors = TREND_COLORS,
  conditionColumns,
}: IUseAgGridProps): IUseAgGridReturn => {
  const {
    areAllRowsExpanded,
    headers,
    simpleColumns,
    isTransposed,
    darkMode,
    handleRowGroupOpened,
    moveColumn,
    updateContentMeasurements,
    autoGroupColumn,
    onAgGridReady,
    contentResizeState,
    useSignalColorsInChart,
  } = props;

  const [popupParent, setPopupParent] = useState<HTMLElement | null>(null);
  const [lastTransposed, setLastTransposed] = useState(!isTransposed);

  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const chartViewSettings = getChartViewSettings(tableBuilderMode, props);
  const showCapsuleHeaders = headers?.type !== TableBuilderHeaderType.None;
  const showTableHeaders = !!simpleColumns.find((column) => column.key === COLUMNS_AND_STATS.name.key);
  const showTable = isTransposed === lastTransposed;
  const autoSizeColumnsDebounce = useDebounce(autoSizeColumns, DEBOUNCE.MEDIUM_WITH_HEADLESS_RENDER_SUPPORT);
  const isContent = isInteractiveContent || headlessRenderMode();
  const transposedHeaderColumn =
    tableBuilderMode === TableBuilderMode.Simple
      ? SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN
      : CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN;

  const gridOptions = getGridOptions({ agGridApi, rowIds });

  const onGridReady = (params: GridReadyEvent) => {
    setAgGridApi(params.api);
    addScreenshotSetup(showChartView);
  };

  const seeqTheme = useMemo(
    () => ({
      'seeq-theme': getSeeqAgChartTheme(darkMode === true, chartViewColors),
    }),
    [darkMode, chartViewColors],
  );

  const autoSizeHelper = useCallback(
    () =>
      resizeAgGridHelper(
        autoSizeColumnsDebounce,
        columnDefs,
        autoGroupColumn,
        isInteractiveContent,
        agGridApi,
        wrapperRef.current,
        showTable,
        setLastTransposed,
        isTransposed,
        data.length,
        updateContentMeasurements,
        onAgGridReady,
      ),
    [
      agGridApi,
      autoGroupColumn,
      autoSizeColumnsDebounce,
      columnDefs,
      isInteractiveContent,
      isTransposed,
      onAgGridReady,
      showTable,
      updateContentMeasurements,
      contentResizeState,
    ],
  );

  useResizeGroupingBar({ wrapperRef, autoGroupColumn });

  useEffect(() => {
    if (shouldAutoSize) {
      autoSizeHelper();
    }
  }, [shouldAutoSize, autoSizeHelper]);

  /* --------- Charts -----------  */
  useEffect(() => {
    if (!showChartView || !agGridApi || (!conditionTableData?.headers?.length && !simpleTableData?.length)) {
      return;
    }

    setPopupParent(chartWrapperRef?.current);
    agGridApi?.getChartRef(_.first(agGridApi?.getChartModels())?.chartId ?? '')?.destroyChart();
    if (!chartViewSettings?.chartType || !chartWrapperRef?.current) {
      regenerateChart('#tableChart', agGridApi, columnDefs);
      persistChart(isInteractiveContent, agGridApi, tableBuilderMode === TableBuilderMode.Simple);
    } else {
      const { cellRange } = chartViewSettings;
      const existingColumns = cellRange?.columns || [];
      const colIds = columnDefs?.map((col) => col.colId) || [];
      const hasAtLeastOneExistingColumn = existingColumns.some((column: ColDef | string) =>
        colIds.includes(typeof column === 'string' ? column : column?.colId),
      );

      // Recalculate cell range if no matching columns are found
      const newSettings = {
        ...chartViewSettings,
        cellRange: {
          ...cellRange,
          columns: hasAtLeastOneExistingColumn ? existingColumns : colIds,
        },
        chartPalette: {
          strokes: chartViewColors,
          fills: chartViewColors,
        },
      };

      agGridApi.restoreChart(newSettings, chartWrapperRef.current!);
    }
    agGridApi?.updateGridOptions(getGridOptions({ agGridApi, rowIds }));
  }, [showChartView, data, agGridApi, showCapsuleHeaders, darkMode, useSignalColorsInChart]);

  useEffect(() => {
    if (showChartView && !isContent) {
      agGridApi?.openChartToolPanel({ chartId: _.first(agGridApi?.getChartModels())?.chartId ?? '' });
    }
  }, [showChartView]);

  useEffect(() => {
    if (autoGroupColumn && areAllRowsExpanded) {
      agGridApi?.setColumnsVisible([transposedHeaderColumn], false);
    } else {
      agGridApi?.setColumnsVisible([transposedHeaderColumn], true);
    }
  }, [autoGroupColumn, agGridApi, showTableHeaders, showCapsuleHeaders, areAllRowsExpanded]);

  useEffect(() => {
    if (areAllRowsExpanded) {
      expandAll(true);
      agGridApi?.expandAll();
    } else {
      expandAll(false);
      agGridApi?.collapseAll();
    }
  }, [areAllRowsExpanded]);

  const expandAll = (expand: boolean) => {
    agGridApi?.forEachNode((node) => {
      if (node?.allChildrenCount ?? 0 > 0) {
        handleRowGroupOpened(undefined, node, expand);
      }
    });
  };

  const handleChartOptionsChanged = useDebounce(() => {
    if (!agGridApi || !showChartView) return;
    persistChart(isInteractiveContent, agGridApi, tableBuilderMode === TableBuilderMode.Simple);
  }, DEBOUNCE.WORKSTEP);

  const handleChartRangeSelectionChanged = useDebounce((params) => {
    if (!agGridApi || !showChartView) {
      return;
    }

    const previousColumns = chartViewSettings?.cellRange?.columns;
    const currentColumns = agGridApi?.getChartModels()?.[0]?.cellRange?.columns;

    const arraysHaveSameElements = (previousColumns?: (string | Column)[], currentColumns?: (string | Column)[]) => {
      if (
        !Array.isArray(previousColumns) ||
        !Array.isArray(currentColumns) ||
        previousColumns.length !== currentColumns.length
      )
        return false;
      return (
        previousColumns.every((item1) => currentColumns.some((item2) => _.isEqual(item1, item2))) &&
        currentColumns.every((item2) => previousColumns.some((item1) => _.isEqual(item1, item2)))
      );
    };

    if (arraysHaveSameElements(previousColumns, currentColumns)) {
      return;
    }

    persistChart(isInteractiveContent, agGridApi, tableBuilderMode === TableBuilderMode.Simple);
  }, DEBOUNCE.WORKSTEP);

  const handleNewColumnsLoaded = () => {
    const currentChartModel = agGridApi?.getChartModels()?.[0];

    /* This ensures that we can select a single series after updating the columns cell range*/
    if (currentChartModel?.chartId && SINGLE_SERIES_CHART_TYPES.includes(currentChartModel?.chartType)) {
      agGridApi?.getChartRef(currentChartModel.chartId)?.destroyChart();
      agGridApi?.restoreChart(currentChartModel, chartWrapperRef.current!);
    }
  };

  const initialGroupOrderComparator = useMemo(() => {
    if (showChartView) return;

    return (params: InitialGroupOrderComparatorParams) => {
      const isSimpleMode = tableBuilderMode === TableBuilderMode.Simple;
      if (params.nodeA.rowIndex === undefined || params.nodeB.rowIndex === undefined || isSimpleMode === isTransposed) {
        return 0;
      }

      const agRowGroupColumn = params.nodeA.rowGroupColumn;
      const cols = isSimpleMode ? simpleColumns : conditionColumns?.rows;
      const seeqRowGroupColumn = cols?.find((column) => column.key === agRowGroupColumn?.getColId());

      if (!seeqRowGroupColumn || seeqRowGroupColumn.sort === undefined) {
        return 0;
      }

      const isDescending = seeqRowGroupColumn.sort.direction === 'desc';
      const header = conditionTableData?.headers.find((header) => header.key === seeqRowGroupColumn.key);

      // All leaf nodes of this group will have the same value for this column, so just grab the first
      const childNodeA = params.nodeA.allLeafChildren?.[0];
      const childNodeB = params.nodeB.allLeafChildren?.[0];

      if ((!isSimpleMode && !header) || !childNodeA || !childNodeB) {
        return 0;
      }

      if (isSimpleMode) {
        const value = simpleTableComparator(childNodeA, childNodeB, seeqRowGroupColumn);
        return seeqRowGroupColumn.sort.direction === 'asc' ? value : -value;
      } else {
        // isDescending isn't actually needed in the codepath we take in conditionTableComparator, but it is
        // needed afterwards
        const value = conditionTableComparator(isDescending, childNodeA, childNodeB, header as ConditionTableHeader);
        return isDescending ? -value : value;
      }
    };
  }, [
    conditionColumns?.rows,
    conditionTableData?.headers,
    isTransposed,
    showChartView,
    simpleColumns,
    tableBuilderMode,
  ]);

  return {
    showTable,
    autoSizeHelper,
    sharedAgGridProps: {
      aggFuncs: {
        stdDev: standardDeviationAggFunc,
        range: rangeAggFunc,
        first: firstNonEmptyValueAggFunc,
        last: lastNonEmptyValueAggFunc,
      },
      animateRows: !headlessRenderMode(),
      autoGroupColumnDef: getAutoGroupByType(tableBuilderMode, columnDefs, t),
      chartThemes: CHART_THEMES,
      chartToolPanelsDef: isContent ? CONTENT_CHART_TOOLS_PANEL_DEF : chartToolPanelsDef,
      customChartThemes: seeqTheme,
      defaultColDef: DEFAULT_COL_DEF,
      domLayout: getDomLayout(isInteractiveContent, data.length),
      enableCharts: true,
      functionsReadOnly: isInteractiveContent, // Re-enable as part of CRAB-40362
      getRowId,
      gridOptions,
      groupAllowUnbalanced: true, // Needed to ensure UOM row is not grouped
      initialGroupOrderComparator,
      modules: [
        AgGridModules.ClipboardModule,
        AgGridModules.RowGroupingModule,
        AgGridModules.MenuModule,
        AgGridModules.GridChartsModule,
      ],
      onChartOptionsChanged: handleChartOptionsChanged,
      onChartRangeSelectionChanged: handleChartRangeSelectionChanged,
      onNewColumnsLoaded: handleNewColumnsLoaded,
      onColumnMoved: (event) => onColumnMoved(event, moveColumn),
      onColumnResized: isInteractiveContent ? undefined : onColumnResized,
      onGridReady,
      onRowDragLeave: (event) => {
        rowNodeOutsideGridRef.current = event.node;
      },
      overlayNoRowsTemplate: '<div></div>',
      popupParent,
      rowData: restrictRowDataIfNecessary(data),
      rowDragManaged: true,
      rowHeight: DEFAULT_TABLE_ROW_HEIGHT,
      rowSelection: 'multiple',
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      suppressContextMenu: true,
      suppressDragLeaveHidesColumns: true,
      suppressFieldDotNotation: true,
      ...(!headlessRenderMode() ? {} : { getChartToolbarItems: () => [] }),
    },
  };
};

export default useAgGridProps;

import _ from 'lodash';
import type { AgChartTheme } from '@ag-grid-enterprise/charts';
import type { ChartToolPanelsDef, ColDef, GridApi, GridOptions } from '@ag-grid-community/core';
import { TREND_COLORS } from '@/trendData/trendData.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { AG_GRID_GROUPING_COL_ID } from '@/tableBuilder/tableBuilder.constants';
import { setChartViewConditionSettings, setChartViewSettings } from '@/tableBuilder/tableBuilder.actions';
import { formatNumber } from '@/utilities/numberHelper.utilities';

const AG_GRID_DEFAULT_CATEGORY = 'AG-GRID-DEFAULT-CATEGORY';

const getCategoryValue = (key: string | number | { labels: string[] }) => {
  const value =
    typeof key === 'object' && key.labels?.length ? key.labels.find((label) => label.includes('_')) ?? key : key;

  const returnValue = typeof value === 'string' && value.includes('_') ? value.split('_').pop() ?? key : key;

  return isNaN(Number(returnValue)) || !returnValue ? returnValue : parseFloat(Number(returnValue).toFixed(3));
};

const getCategoryKey = (datum: any, calloutKey?: string) => {
  const defaultReturn = _.values(datum)[0];
  /* Using the categoryId as key if (None) header is selected */
  if (_.has(datum, AG_GRID_DEFAULT_CATEGORY)) {
    return _.has(datum, `${AG_GRID_DEFAULT_CATEGORY}.id`)
      ? _.get(datum, `${AG_GRID_DEFAULT_CATEGORY}.id`)
      : _.get(datum, AG_GRID_DEFAULT_CATEGORY) - 1;
  }

  /* Grouped datum has slightly different structure
   *  All labels will have the same columnId, thus we look up the first one */
  if (_.has(datum, AG_GRID_GROUPING_COL_ID)) {
    const labels = _.get(datum, AG_GRID_GROUPING_COL_ID).labels;
    const label = labels.find((label: string) => label?.includes('_'));
    return label ? label.split('_')[0] : defaultReturn;
  }

  if (calloutKey && _.has(datum, calloutKey)) {
    const value = datum[calloutKey]?.value || datum[calloutKey];
    return typeof value === 'string' ? value.split('_').slice(0, -1).join('_') : value;
  }

  /* This handles the case where the selected category has an id (works like a safe-net before returning default) */
  const idKey = _.findKey(datum, (value) => _.has(value, 'id'));

  return idKey ? datum[idKey].id : defaultReturn;
};

const getTableCellValue = ({
  rowIds,
  agGridApi,
  datum,
  chartKey,
  calloutKey,
  defaultValue,
}: {
  rowIds: Record<string, { index: number }>;
  agGridApi?: GridApi<any>;
  datum?: any;
  chartKey?: string;
  calloutKey?: string;
  defaultValue?: string;
}) => {
  const defaultCellValue = formatNumber(defaultValue)?.toString();
  const key = getCategoryKey(datum, calloutKey);
  const rowId =
    calloutKey && !datum[AG_GRID_DEFAULT_CATEGORY]
      ? key
      : _.findKey(rowIds, (rowId) => Object.values(rowId).includes(key));

  const rowNode = agGridApi?.getRowNode(rowId);

  if (!rowNode || !chartKey) {
    return defaultCellValue;
  }

  return (
    agGridApi?.getCellValue({
      rowNode,
      colKey: chartKey,
      useFormatter: true,
    }) ?? defaultCellValue
  );
};

const createSeriesConfig = ({
  agGridApi,
  rowIds,
  isScatter = false,
}: {
  rowIds: Record<string, { index: number }>;
  agGridApi?: GridApi<any>;
  isScatter?: boolean;
}) => ({
  tooltip: {
    renderer: (params: any) => {
      const value = getTableCellValue({
        rowIds,
        agGridApi,
        datum: params?.datum,
        chartKey: params?.yKey,
        calloutKey: isScatter ? params?.labelKey : params?.xKey,
        defaultValue: params?.value,
      });

      return {
        title: params.datum.title,
        content: value,
      };
    },
  },
  label: {
    formatter: (params: any) =>
      getTableCellValue({
        rowIds,
        agGridApi,
        datum: params?.datum,
        chartKey: params?.yKey,
        calloutKey: isScatter ? params?.labelKey : params?.xKey,
        defaultValue: params?.value,
      }),
  },
});

export const persistChart = (isInteractiveContent: boolean, api: GridApi<any> | undefined, isSimpleChart?: boolean) => {
  if (isInteractiveContent || headlessRenderMode()) {
    return;
  }
  const setSettings = isSimpleChart ? setChartViewSettings : setChartViewConditionSettings;
  setSettings(_.first(api?.getChartModels()));
};

export const regenerateChart = (containerId: string, api: GridApi<any> | undefined, columnDefs: ColDef<any, any>[]) => {
  api?.createRangeChart({
    chartContainer: document.querySelector(containerId) as HTMLElement,
    cellRange: {
      columns: columnDefs.map((column) => column.colId) as string[],
    },
    chartType: 'stackedColumn',
  });
};

export const SINGLE_SERIES_CHART_TYPES = ['pie', 'histogram'];

export const getSeeqAgChartTheme = (darkMode?: boolean, chartViewColors?: string[] | null) =>
  ({
    palette: {
      fills: chartViewColors ?? TREND_COLORS,
      strokes: chartViewColors ?? TREND_COLORS,
    },
    overrides: {
      common: {
        axes: {
          'category': {
            label: {
              color: darkMode ? '#C2BCB0' : '#242525',
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
              formatter: (params) => getCategoryValue(params?.value?.value),
            },
          },
          'grouped-category': {
            label: {
              color: darkMode ? '#C2BCB0' : '#242525',
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
              formatter: (params) => getCategoryValue(params?.value)?.toString(),
            },
          },
          'number': {
            label: {
              color: darkMode ? '#C2BCB0' : '#242525',
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
            },
          },
        },
        padding: { right: 40 },
        background: {
          fill: darkMode ? '#242525' : 'white',
        },
        title: {
          color: darkMode ? '#C2BCB0' : 'black',
          enabled: false,
          fontSize: 16,
          fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
          text: 'Title',
        },
        series: {
          label: {
            enabled: !headlessRenderMode(),
            fontSize: 12,
            fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
            color: darkMode ? '#C2BCB0' : 'black',
          },
        },
        legend: {
          enabled: true,
          spacing: 30,
          item: {
            label: {
              fontSize: 12,
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
              color: darkMode ? '#C2BCB0' : 'black',
            },
          },
        },
      },
    },
  } as AgChartTheme);

export const getGridOptions = ({
  agGridApi,
  rowIds,
}: {
  rowIds: Record<string, { index: number }>;
  agGridApi?: GridApi<any>;
}): GridOptions => ({
  chartThemeOverrides: {
    bar: {
      series: createSeriesConfig({ agGridApi, rowIds }),
    },
    line: {
      series: createSeriesConfig({ agGridApi, rowIds }),
    },
    area: {
      series: createSeriesConfig({ agGridApi, rowIds }),
    },
    scatter: {
      series: createSeriesConfig({ agGridApi, rowIds, isScatter: true }),
    },
    bubble: {
      series: createSeriesConfig({ agGridApi, rowIds, isScatter: true }),
    },
    pie: {
      series: {
        tooltip: {
          renderer: (params) => {
            const value = getTableCellValue({
              rowIds,
              agGridApi,
              datum: params?.datum,
              chartKey: params?.sectorLabelKey,
              calloutKey: params?.calloutLabelKey,
              defaultValue: params?.datum[params.sectorLabelKey!],
            });

            return {
              title: params.datum.title,
              content: value,
            };
          },
        },
        sectorLabel: {
          formatter: (params) =>
            getTableCellValue({
              rowIds,
              agGridApi,
              datum: params?.datum,
              chartKey: params?.sectorLabelKey,
              calloutKey: params?.calloutLabelKey,
              defaultValue: params?.value,
            }),
        },

        calloutLabel: {
          formatter: (params) => getCategoryValue(params?.value)?.toString(),
        },
      },
    },
    donut: {
      series: {
        tooltip: {
          renderer: (params) => {
            const value = getTableCellValue({
              rowIds,
              agGridApi,
              datum: params?.datum,
              chartKey: params?.sectorLabelKey,
              calloutKey: params?.calloutLabelKey,
              defaultValue: params?.datum[params.sectorLabelKey!],
            });

            return {
              title: params.datum.title,
              content: `${getCategoryValue(params.datum[params.calloutLabelKey!])}: ${value}`,
            };
          },
        },
        sectorLabel: {
          formatter: (params) =>
            getTableCellValue({
              rowIds,
              agGridApi,
              datum: params?.datum,
              chartKey: params?.sectorLabelKey,
              calloutKey: params?.calloutLabelKey,
              defaultValue: params?.value,
            }),
        },
        calloutLabel: {
          formatter: (params) => getCategoryValue(params.value).toString(),
        },
      },
    },
    histogram: {
      series: {
        tooltip: {
          renderer: (params) => {
            const formattedTitle = `${params.xName}: ${params.datum.domain[0]} - ${params.datum.domain[1]}`;
            return {
              title: formattedTitle,
            };
          },
        },
      },
    },
  },
});

export const chartToolPanelsDef: ChartToolPanelsDef = {
  defaultToolPanel: 'settings',
  settingsPanel: {
    chartGroupsDef: {
      columnGroup: ['column', 'stackedColumn', 'normalizedColumn'],
      barGroup: ['bar', 'stackedBar', 'normalizedBar'],
      pieGroup: ['donut', 'pie'],
      scatterGroup: ['scatter', 'bubble'],
      hierarchicalGroup: ['treemap', 'sunburst'],
      lineGroup: ['line'],
      specializedGroup: ['waterfall', 'heatmap'],
      areaGroup: ['area', 'stackedArea', 'normalizedArea'],
      polarGroup: ['radarLine', 'radarArea', 'nightingale', 'radialColumn', 'radialBar'],
      statisticalGroup: ['histogram', 'boxPlot', 'rangeBar', 'rangeArea'],
    },
  },
  dataPanel: {
    groups: [
      { type: 'seriesChartType', isOpen: true },
      { type: 'categories', isOpen: true },
      { type: 'series', isOpen: true },
    ],
  },
  formatPanel: {
    groups: [
      { type: 'chart', isOpen: false },
      { type: 'titles', isOpen: false },
      { type: 'legend', isOpen: false },
      { type: 'horizontalAxis', isOpen: false },
      { type: 'verticalAxis', isOpen: false },
      { type: 'series', isOpen: false },
    ],
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '@/main/LoadingSpinner.molecule';
import { isSelfServicePasswordResetEnabled, seeqVersion } from '@/services/systemConfiguration.utilities';
import { FORM_ERROR, FormElement } from '@/formbuilder/formBuilder.constants';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { useResetPassword } from '@/main/auth/hooks/useResetPassword.hook';
import { goTo } from '@/main/routing.utilities';
import seeqLogoDarkBlueSm from '../../../resources/img/Seeq_logo_darkBlue_sm.png';
import { getPasswordErrorText, validPassword } from '@/utilities/password.utilities';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import _ from 'lodash';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { setCurrentUser } from '@/workbench/workbench.actions';
import { errorToast } from '@/utilities/toast.utilities';

export const ResetPassword: React.FunctionComponent = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const { t } = useTranslation();
  const {
    formData: { newPassword, passwordCheck },
    updateFormData,
    doResetPassword,
    resetPasswordError,
  } = useResetPassword();

  const [config, setConfig] = useState<any>({});

  const authenticated = isCsrfSet();
  if (authenticated && _.isEmpty(currentUser)) {
    setCurrentUser().catch((error) => errorToast({ httpResponseOrError: error }));
  }

  const { selfServicePasswordResetEnabled } = config;

  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  const { email } = _.pick(urlParams, ['email']);
  const isPasswordResetForCurrentUser = () => email === currentUser.email;
  const canResetPassword = () => {
    if (!authenticated) {
      return true;
    }
    return isPasswordResetForCurrentUser();
  };

  useEffect(() => {
    document.body.classList.add('color_topic', 'light');

    setConfig({
      selfServicePasswordResetEnabled: isSelfServicePasswordResetEnabled(),
    });
  }, []);

  const formDefinition: FormElement[] = [
    {
      component: 'TextFieldFormComponent',
      name: 'password',
      testId: 'password',
      label: 'USER.NEW_PASSWORD',
      placeholder: 'USER.PASSWORD_PROMPT',
      type: 'password',
      value: newPassword,
      onChange: (password) => updateFormData({ newPassword: password as string }),
      size: 'sm',
      autoComplete: 'new-password',
      required: true,
      autoFocus: true,
      validation: (newPassword: string | number) => !validPassword(newPassword as string),
      customErrorText: getPasswordErrorText(newPassword),
    },
    {
      component: 'TextFieldFormComponent',
      name: 'passwordCheck',
      testId: 'passwordCheck',
      label: 'USER.PASSWORD_CHECK',
      placeholder: 'USER.PASSWORD_CHECK_PROMPT',
      type: 'password',
      value: passwordCheck,
      onChange: (passwordCheck) => updateFormData({ passwordCheck: passwordCheck as string }),
      size: 'sm',
      autoComplete: 'new-password',
      required: true,
      validation: (passwordCheck: string | number) => (passwordCheck as string) !== newPassword,
      extendValidation: true,
      customErrorText: passwordCheck !== newPassword ? 'USER.PASSWORD_MISMATCH' : '',
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'resetPasswordError',
      testId: 'specResetPasswordError',
      type: FORM_ERROR,
      value: resetPasswordError,
      failForm: false,
      includeIf: !!resetPasswordError,
    },
  ];

  return (
    <>
      <LoadingSpinner top={true} />
      <div className="loginFooter pl10 pr10 sq-darkish-gray font-size-smaller">
        <small>{seeqVersion()}</small>
      </div>

      <div data-testid="resetPasswordPage" className="flexRowContainer fullViewport splashScreenLight loginBackdrop">
        <div className="loginPanel flexRowContainer min-width-400 overlayPanel flexFill flexCenter col-sm-3 offset-sm-2">
          <div className="width-maximum pl20 pr20">
            <div data-testid="logoAndSlogan" className="flexRowContainer flexCenter mb25">
              <img src={seeqLogoDarkBlueSm} className="mb15" />
              <span className="h4 loginSlogan">{t('RESET_PASSWORD_PANEL.SLOGAN')}</span>

              <span className="text-color-default">{t('RESET_PASSWORD_PANEL.SET_PASSWORD_PROMPT')}</span>
            </div>

            {!selfServicePasswordResetEnabled && (
              <div className="text-center">{t('RESET_PASSWORD_PANEL.FEATURE_DISABLED')}</div>
            )}
            {selfServicePasswordResetEnabled && !canResetPassword() && (
              <div className="text-center">
                {t('RESET_PASSWORD_PANEL.DIFFERENT_USER_LOGGED_IN', {
                  currentEmail: currentUser.email,
                  resetEmail: email,
                })}
              </div>
            )}
            {selfServicePasswordResetEnabled && canResetPassword() && (
              <SimpleSaveFormBuilder
                testId="resetPassword"
                formDefinition={formDefinition}
                submitFn={doResetPassword}
                closeFn={() => goTo('/login')}
                submitBtnLabel="RESET_PASSWORD_PANEL.SET_PASSWORD"
                buttonExtraClassNames="mt25"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

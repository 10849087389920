import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Accordion, Alert, Icon } from '@seeqdev/qomponents';
import {
  provideVisualizationData,
  serializeDisplayRange,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { NewLineToBr } from '@/core/NewLineToBr.atom';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { TableBuilderMode } from '@/tableBuilder/tableBuilder.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { ParametersMap } from '@/utilities/formula.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { TableBuilderSimpleAgGrid } from '@/tableBuilder/TableBuilderSimpleAgGrid.organism';
import { TableBuilderConditionAgGrid } from '@/tableBuilder/TableBuilderConditionAgGrid.organism';
import {
  AgGridProps,
  ColumnOrRowWithDefinitions,
  ColumnToThresholdsCondition,
  ColumnToThresholdsSimple,
  ConditionTableColumnsAndRows,
  ConditionTableData,
  ContentMeasurements,
  OtherColumns,
  SimpleTableRow,
  TableBuilderHeaders,
  TableBuilderSortDirection,
  TableTextFormatter,
} from '@/tableBuilder/tableBuilder.types';
import { useDidMount } from 'rooks';
import { finishAgGridLoading } from '@/utilities/utilities';
import type { ChartModel } from '@ag-grid-community/core';
import classNames from 'classnames';

export interface ChartSettingsInterface {
  title: string;
  legend: boolean;
  dataLabels: boolean;
  categoryLabels: boolean;
  chartType?: string;
  categoryColumns: string[];
  rows: string[];
  columns: string[];
  showSettings?: boolean;
  position?: { x: number; y: number };
  stacked?: boolean;
}

export interface TableBuilderPropsOnlyProps extends Omit<AgGridProps, 'otherColumns'> {
  canEdit: boolean;
  isSimpleMode: boolean;
  startMode: boolean;
  isLoading: boolean;
  hasOnlyStringSeries: boolean;
  hasNumericAndStringSeries: boolean;
  hasOnlyStringMetrics: boolean;
  hasNumericAndStringMetrics: boolean;
  setCellText: (key: string, value: string, id?: string) => void;
  setHeaderText: (columnKey: string, text: string) => void;
  setColumnFilter: (key: string, filter: TableColumnFilter) => void;
  simpleTableData: SimpleTableRow[];
  conditionTableData: ConditionTableData;
  headers: TableBuilderHeaders;
  simpleColumns: ColumnOrRowWithDefinitions[];
  conditionColumns: ConditionTableColumnsAndRows;
  isTransposed: boolean;
  useSignalColorsInChart: boolean;
  isTableStriped: boolean;
  isPresentationMode: boolean;
  isViewOnlyMode: boolean;
  displayRange: RangeExport;
  timezone: {
    name: string;
  };
  displayMetricOnTrend: (metricId: string, formulaItemId: string, start: number, end: number, event: any) => void;
  columnToThresholdsForCondition: ColumnToThresholdsCondition;
  columnToThresholdsForSimple: ColumnToThresholdsSimple;
  distinctStringValueMap: {
    [mode: string]: {
      [columnKey: string]: string[];
    };
  };
  textFormatter: TableTextFormatter;
  moveColumn: (key: string, newKey: string) => void;
  canSort: boolean;
  maxSortLevel: number;
  sortByColumn: ((key: string, direction: TableBuilderSortDirection) => void) | undefined;
  removeColumn: (key: string) => void;
  setActiveTool: (toolName: string) => void;
  fetchFailedMessage:
    | {
        message: string;
        apiMessage: string;
      }
    | undefined;
  setActiveRefElement: (element: Node | null) => void;
  showChartView: boolean;
  chartViewSettings: ChartSettingsInterface | ChartModel;
  showConditionChartView: boolean;
  areAllRowsExpanded: boolean;
  chartViewConditionSettings: ChartModel;
  fetchStringColumnValues: (columnKey: string, isStringColumn: boolean, cancellationGroup: string) => void;
  setChartViewSettings: (property: any) => void;
  onContentLoad?: () => void;
  updateContentMeasurements?: (measurements: ContentMeasurements) => void;
  afterChartUpdate?: () => void;
  chartColors: ParametersMap;
  darkMode: boolean;
  otherColumns?: OtherColumns;
  hasMoreData?: boolean;
  setHideColumnHeadersWhenCopying: React.Dispatch<React.SetStateAction<boolean>>;
  tableItems: any[];
  rawConditionTableData: ConditionTableData;
  rawSimpleTableData: SimpleTableRow[];
  hideInteractiveContentActions?: boolean;
  contentResizeState?: number;
  isFormulaAcrossTableProcessing: boolean;
  isFormulaAcrossTable: boolean;
}

export const TableBuilderPropsOnly: React.FunctionComponent<TableBuilderPropsOnlyProps> = (
  props: TableBuilderPropsOnlyProps,
) => {
  const {
    isSimpleMode,
    startMode,
    canEdit,
    simpleTableData,
    conditionTableData,
    isPresentationMode,
    isTransposed,
    isTableStriped,
    columnToThresholdsForCondition,
    columnToThresholdsForSimple,
    distinctStringValueMap,
    conditionColumns,
    simpleColumns,
    fetchFailedMessage,
    setActiveTool,
    showChartView: showSimpleChartView,
    showConditionChartView,
    onContentLoad,
    updateContentMeasurements,
    onAgGridReady,
    isFormulaAcrossTableProcessing,
    isFormulaAcrossTable,
  } = props;
  const { t } = useTranslation();
  const [activeAccordion, setActiveAccordion] = React.useState<string>('');

  useDidMount(() => {
    onContentLoad?.();
  });

  const hasFetchError = !_.isEmpty(fetchFailedMessage?.message);

  // If there is an error ag-grid won't render, so fake that it finished to ensure the screenshot finishes (CRAB-41739)
  useEffect(() => {
    if (hasFetchError || startMode) {
      finishAgGridLoading();
      onAgGridReady?.();
    }
  }, [onAgGridReady, hasFetchError, startMode]);

  if (headlessRenderMode() && !startMode) {
    provideVisualizationData({ ...props, visualization: Visualization.TABLE }, { displayRange: serializeDisplayRange });
  }

  // Ensures alert is not centered in headless mode, because otherwise renderer only captures empty space
  const alertWrapper = (alert: React.ReactNode) => (
    <div className={isPresentationMode ? 'width-300' : 'flexRowContainer flexCenter flexFill'}>{alert}</div>
  );

  const statColumnIsSelected = () =>
    _.chain(simpleColumns)
      .some((col) => col.key.startsWith('statistic') || col.key === COLUMNS_AND_STATS.metricValue.key)
      .value();

  return (
    <>
      <div
        id="tableBuilder"
        className={classNames('flexRowContainer flexFill', {
          p20: !(isPresentationMode || (isSimpleMode ? showSimpleChartView : showConditionChartView)),
        })}>
        {((!statColumnIsSelected() && showSimpleChartView) || _.isEmpty(simpleColumns)) &&
          !startMode &&
          isSimpleMode && (
            <div className="flexCenter flexColumnContainer flexFill">
              <Alert
                dismissible={false}
                variant="theme"
                extraClassNames="min-width-400 fs16 p25 screenshotSizeToContent">
                <Icon icon={isTransposed ? 'fc-add-row' : 'fc-add-column'} extraClassNames="p5" type="theme" />
                <span> {t(isTransposed ? 'TABLE_BUILDER.SELECT_ROW' : 'TABLE_BUILDER.SELECT_COLUMN')} </span>
              </Alert>
            </div>
          )}
        {hasFetchError &&
          !startMode &&
          alertWrapper(
            <Alert
              dismissible={false}
              variant="theme"
              testId="has-fetch-error-alert"
              extraClassNames="max-width-400 screenshotSizeToContent fs fs16">
              <NewLineToBr lineToBreak={fetchFailedMessage?.message ?? ''} />

              {fetchFailedMessage?.apiMessage && (
                <Accordion
                  extraClassNames="pt10 font-size-smaller"
                  value={activeAccordion}
                  onItemSelect={(key) => setActiveAccordion(key)}
                  testId="apiMessage"
                  accordionItems={[
                    {
                      value: '0',
                      id: '0',
                      trigger: (
                        <div
                          data-testid="detailsToggle"
                          className="sq-text-primary cursorPointer flexColumnContainer flexAlignCenter flexSpaceBetween">
                          <span className="pr5">{t('TABLE_BUILDER.VIEW_DETAILS')}</span>
                          <Icon icon={activeAccordion === '0' ? 'fa-chevron-down' : 'fa-chevron-right'} />
                        </div>
                      ),
                      content: <span>{fetchFailedMessage.apiMessage}</span>,
                    },
                  ]}
                />
              )}
            </Alert>,
          )}
        {simpleColumns.length > 0 &&
          isSimpleMode &&
          !startMode &&
          !hasFetchError &&
          !(isFormulaAcrossTable && isFormulaAcrossTableProcessing) && (
            <TableBuilderSimpleAgGrid
              {...props}
              isStriped={isTableStriped}
              sortByColumn={props.sortByColumn ?? _.noop}
              columnToThresholds={columnToThresholdsForSimple}
              distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Simple]}
              otherColumns={props.otherColumns?.simple ?? {}}
              updateContentMeasurements={updateContentMeasurements}
            />
          )}
        {!isSimpleMode && !startMode && !hasFetchError && (
          <TableBuilderConditionAgGrid
            {...props}
            tableData={conditionTableData}
            columns={conditionColumns}
            isStriped={isTableStriped}
            sortByColumn={props.sortByColumn ?? _.noop}
            columnToThresholds={columnToThresholdsForCondition}
            distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Condition]}
            otherColumns={props.otherColumns?.condition ?? {}}
          />
        )}
        {startMode &&
          alertWrapper(
            <Alert dismissible={false} variant="theme" extraClassNames="width-300 fs16 p25 screenshotSizeToContent">
              {!canEdit && <p>{t('TABLE_BUILDER.START_MODE_NO_CONTENT')}</p>}
              {canEdit && (
                <>
                  <p>{t('TABLE_BUILDER.START_MODE')}</p>
                  <a className="cursorPointer" onClick={() => setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}>
                    <Icon icon="fa-plus-circle" extraClassNames="p5" type="theme" testId="addFirstMetricButton" />
                    <span>{t('TABLE_BUILDER.ADD_METRIC')}</span>
                  </a>
                </>
              )}
            </Alert>,
          )}
      </div>
    </>
  );
};

import { FixedListSearchV1, PropertySearchV1 } from '@/sdk';
import { ItemFinderSearchesInputV1 } from '@/sdk/model/ItemFinderSearchesInputV1';
import { SearchTypeEnum as _SearchTypeEnum } from '@/sdk/model/PropertySearchV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import type { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { DURATION_TIME_UNITS_ALL, MOMENT_DURATION_UNITS, MomentDurationUnit } from '@/main/app.constants';

interface SearchExtraProps {
  searchId: string | number;
  ancestorId?: string;
  folderId?: string;
  predicates?: any[];
  types?: string[];
  isInclude?: boolean;
}

export type PropertySearch = PropertySearchV1 & SearchExtraProps;
export type FixedListSearch = FixedListSearchV1 & SearchExtraProps;
export type OtherSearch = { searchType: string } & SearchExtraProps;

export type AIPayload = Omit<ItemFinderSearchesInputV1, 'limit'>;

export const SearchTypeEnum = {
  ..._SearchTypeEnum,
  FOLDER: 'PROPERTY_FOLDER',
  WORKBENCH: 'PROPERTY_WORKBENCH',
  ASSET_TREE: 'PROPERTY_ASSET_TREE',
};

export type SearchType = typeof SearchTypeEnum[keyof typeof SearchTypeEnum];

export type Search = FixedListSearch | PropertySearch | OtherSearch;

export const SearchOptions = [
  { value: SearchTypeEnum.PROPERTY, text: 'ITEM_FINDER.PROPERTY', description: 'ITEM_FINDER.PROPERTY_DESCRIPTION' },
  {
    value: SearchTypeEnum.FIXEDLIST,
    text: 'ITEM_FINDER.FIXED_LIST.SEARCH_OPTION',
    description: 'ITEM_FINDER.FIXED_LIST_DESCRIPTION',
  },
];

export const VantageSearchOptions = [
  { value: SearchTypeEnum.WORKBENCH, text: 'ITEM_FINDER.WORKBENCH', description: 'ITEM_FINDER.WORKBENCH_DESCRIPTION' },
  {
    value: SearchTypeEnum.ASSET_TREE,
    text: 'ITEM_FINDER.ASSET_TREE',
    description: 'ITEM_FINDER.ASSET_TREE_DESCRIPTION',
  },
  { value: SearchTypeEnum.FOLDER, text: 'ITEM_FINDER.FOLDER', description: 'ITEM_FINDER.FOLDER_DESCRIPTION' },
  {
    value: SearchTypeEnum.PROPERTY,
    text: 'ITEM_FINDER.PROPERTY',
    description: 'ITEM_FINDER.PROPERTY_DESCRIPTION_VANTAGE',
  },
  {
    value: SearchTypeEnum.FIXEDLIST,
    text: 'ITEM_FINDER.FIXED_LIST.SEARCH_OPTION_VANTAGE',
    description: 'ITEM_FINDER.FIXED_LIST_DESCRIPTION_VANTAGE',
  },
];

export const searchTypesNonAssets = [
  SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredCondition,
  SeeqNames.Types.CalculatedSignal,
  SeeqNames.Types.StoredSignal,
  SeeqNames.Types.CalculatedScalar,
  SeeqNames.Types.LiteralScalar,
  SeeqNames.Types.ThresholdMetric,
];

export const vantageConditionSearchTypes = [
  SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredCondition,
  SeeqNames.Types.Condition,
  SeeqNames.Types.Asset,
];

export const propertyTypes = [
  { value: SeeqNames.Types.Condition, text: 'ITEM_FINDER.TYPES_CONDITION' },
  { value: SeeqNames.Types.Signal, text: 'ITEM_FINDER.TYPES_SIGNAL' },
  { value: SeeqNames.Types.Scalar, text: 'ITEM_FINDER.TYPES_SCALAR' },
  { value: SeeqNames.Types.ThresholdMetric, text: 'ITEM_FINDER.TYPES_THRESHOLD_METRIC' },
  { value: SeeqNames.Types.Asset, text: 'ITEM_FINDER.TYPES_ASSET' },
];

export const defaultLookBack: ValueWithUnitsItem = {
  units: MOMENT_DURATION_UNITS.DAYS,
  value: 7,
};

export const defaultLookAhead: ValueWithUnitsItem = {
  units: MOMENT_DURATION_UNITS.DAYS,
  value: 0,
};

/** 30 days */
export const MAX_SAFE_LOOK_BACK_IN_SECONDS = 30 * 24 * 60 * 60;

const DURATION_UNITS_TO_INCLUDE: MomentDurationUnit[] = ['h', 'd', 'w'];

export const DURATION_UNITS = DURATION_TIME_UNITS_ALL.filter(
  (unit) => unit.momentUnit && DURATION_UNITS_TO_INCLUDE.includes(unit.momentUnit as MomentDurationUnit),
);

import { DEBOUNCE } from '@/core/core.constants';
import { flux } from '@/core/flux.module';
import type { ColumnResizedEvent, ColumnRowGroupChangedEvent, RowGroupOpenedEvent } from '@ag-grid-community/core';
import _ from 'lodash';
import { sqTableBuilderStore } from '@/core/core.stores';
import { getFullGroupedNodePath } from '@/tableBuilder/tableBuilderAgGrid.utilities';
import { AgGridAggregationFunction } from '@/tableBuilder/tableBuilder.constants';

/**
 * Sets the width for a table column.
 *
 * @param key - The key that identifies the column.
 * @param newWidth - New width for the column
 */
export function setColumnWidth(key: string, newWidth: number) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_WIDTH', { key, newWidth });
}

/**
 * Removes/Resets the width for a table column.
 *
 * @param key - The key that identifies the column.
 */
export function resetColumnWidth(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_WIDTH', { key, newWidth: undefined });
}

const resizeColumnDebounced = _.debounce(setColumnWidth, DEBOUNCE.MEDIUM);

export const onColumnResized = (event: ColumnResizedEvent) => {
  const shouldResize = ['uiColumnResized', 'api'].includes(event.source);
  if (!event.column || !shouldResize) {
    return;
  }

  resizeColumnDebounced(event.column.getColId(), event.column.getActualWidth());
};

export function groupByColumn(key: string) {
  if (!sqTableBuilderStore.autoGroupColumn) {
    toggleRowGroupingEnabled();
  }
  flux.dispatch('TABLE_BUILDER_GROUP_BY_ROW', { key });
}

export function handleRowGroupChanged(event: ColumnRowGroupChangedEvent<any, any>) {
  if (event.columns && event.source === 'toolPanelUi') {
    event.columns
      .filter((column) => column.isRowGroupActive())
      .forEach((column) => {
        flux.dispatch('TABLE_BUILDER_GROUP_BY_ROW', { key: column.getColId() });
      });

    event.columns
      .filter((column) => !column.isRowGroupActive())
      .forEach((column) => {
        flux.dispatch('TABLE_BUILDER_UNGROUP_ROW', { key: column.getColId() });
      });
    flux.dispatch('TABLE_BUILDER_SET_ROW_GROUP_ORDER', {
      // event.columns will only contain the affected columns, but that doesn't tell us the position of the column
      // with respect to not-affected columns. So we use the api to get the full order.
      order: event.api.getRowGroupColumns().map((c) => c.getColId()),
    });
  }
}

export function setAreAllRowsExpanded(areAllRowsExpanded: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_ARE_ALL_ROWS_EXPANDED', { areAllRowsExpanded });
}

export function setAggregationOnColumn(key: string, aggregationFunction: AgGridAggregationFunction) {
  flux.dispatch('TABLE_BUILDER_SET_AGGREGATION_FUNCTION', { key, aggregationFunction });
}

export function handleRowGroupOpened(event?: RowGroupOpenedEvent<any, any>, node?: any, expanded?: boolean) {
  if (event?.node?.key === null) {
    return;
  }

  flux.dispatch('TABLE_BUILDER_SET_ROW_GROUP_SHOW', {
    fullPath: getFullGroupedNodePath(event?.node ?? node),
    expanded: event?.expanded ?? expanded,
  });
}

export function toggleRowGroupingEnabled() {
  flux.dispatch('TABLE_BUILDER_TOGGLE_ROW_GROUPING');
}
